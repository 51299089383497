<template>
  <BasicModal
    :title="info && info.id ? '编辑消息通知' : '添加消息通知'"
    width="600px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :footer="null"
    @cancel="cancel"
  >
    <div class="project-edit-content">
      <BasicForm
        class="project-edit-form"
        ref="formRef1"
        :labelCol="{ span: 6 }"
        :formItemsMap="formItemsMap1"
        v-model:formData="formData1"
      ></BasicForm>
    </div>
    <div class="actions">
      <a-button type="primary" :loading="loading" @click="ok('01')"
        >保存草稿</a-button
      >
      <a-button @click="ok('02')">直接发布</a-button>
      <a-button @click="cancel">取消</a-button>
    </div>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  ref,
  defineEmits,
  reactive,
  onMounted,
  nextTick,
  watch,
} from "vue";
import { message } from "ant-design-vue";
import { apiRole, apiUser } from "@/api/authority.js";
import { apiDictListByCode } from "@/api/common";
import { apiMessage } from "@/api/backManage/message.js";
import { useUserStore } from "@/store/modules/user";
import { apiEnterprise } from "@/api/tenant.js";
// import { validatePhone } from "@/components/basic/form/validator";

const userStore = useUserStore();
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef1 = ref(null);


onMounted(async () => {
  await initFormItems();
  if (props.info) {
    initDetail();
  }
  formItemsMap1.user.disabled = true;
});

const initDetail = () => {
  apiMessage.detail(props.info.id).then((res) => {
    console.log(45, res.result);
    formData1.tenantId = [res.result.tenantId];

    formData1.msgTitle = res.result.msgTitle;
    formData1.msgContent = res.result.msgContent;
    formData1.msgType = res.result.msgType;
    nextTick(() => {
      if (userStore.isTenantAdmin) {
        if (formData1.msgType == 3) {
          formData1.noticeIds = [];
        } else {
          formData1.noticeIds = res.result.noticeUserInfo.map(
            (item) => item.userId
          );
        }
      } else {
        if (formData1.msgType == 3) {
          let result = [];
          res.result.noticeUserInfo.forEach((item) => {
            saveData.value.forEach((inner) => {
              if (item.userId == inner.id && inner.roleTypes.includes(2)) {
                result.push(inner.id);
              }
            });
          });
          formData1.noticeIds = result;
        } else {
          formData1.noticeIds = res.result.noticeUserInfo.map(
            (item) => item.userId
          );
        }
      }
    });
    formData1.noticeType = [];
    console.log(23232, formData1.noticeIds);
    if (res.result.emailFlag == 1) {
      formData1.noticeType.push(2);
    }
    if (res.result.mailFlag == 1) {
      formData1.noticeType.push(1);
    }
  });
};

const formItemsMap1 = reactive({
  user: {
    label: "所属租户",
    prop: "tenantId",
    type: "select",
    fieldNames: {
      label: "tenantName",
      value: "tenantId",
    },
    required: true,
    mode: "multiple",
    options: [],
    disabled: false,
    span: 18,
    requiredMessage: "请选择",
  },
  type: {
    label: "标题",
    prop: "msgTitle",
    type: "input",
    span: 18,
    required: true,
  },
  messageType: {
    label: "类型",
    prop: "msgType",
    type: "select",
    required: false,
    options: [],
    span: 18,
    requiredMessage: "请选择",
  },
  content: {
    label: "内容",
    prop: "msgContent",
    type: "textarea",
    span: 18,
  },
  receive: {
    label: "接收用户",
    prop: "noticeIds",
    type: "select",
    fieldNames: {
      label: "username",
      value: userStore.isTenantAdmin ? "userId" : "id",
    },
    required: true,
    mode: "multiple",
    options: [],
    span: 18,
    requiredMessage: "请选择",
  },
  noticeType: {
    label: "通知方式",
    prop: "noticeType",
    type: "checkboxGroup",
    options: [
      {
        label: "站内信",
        value: 1,
      },
      {
        label: "邮件",
        value: 2,
      },
    ],
    required: true,
    span: 18,
    requiredMessage: "请选择",
  },
});
const formData1 = reactive({
  tenantId: [],
  msgTitle: "",
  msgType: "",
  msgContent: "",
  noticeIds: [],
  noticeType: [],
});

watch(
  () => formData1.msgType,
  (newVal) => {
    if (userStore.isTenantAdmin) {
      if (newVal == 3) {
        formItemsMap1.receive.disabled = true;
        formItemsMap1.receive.required = false;
        formData1.noticeIds = [];
      } else {
        formItemsMap1.receive.disabled = false;
        formItemsMap1.receive.required = true;
      }
    } else {
      formData1.noticeIds = [];
      if (newVal == 3) {
        formItemsMap1.receive.options =
          saveData.value &&
          saveData.value.filter((item) => item.roleTypes.includes(2));
      } else {
        formItemsMap1.receive.options = saveData.value;
      }
    }
  }
);

const saveData = ref(null);
const initFormItems = async () => {
  apiDictListByCode("MESSAGE_TYPE").then((res) => {
    res.result.forEach((item) => {
      if (item.value == 1) {
        item.disabled = true;
      }
    });
    formItemsMap1.messageType.options = res.result || [];
  });

  let { result } = await apiRole.tenants_v1();
  formItemsMap1.user.options = result || [];
  formData1.tenantId = [userStore.userInfo.tenantId];

  if (userStore.isTenantAdmin) {
    let res = await apiEnterprise.userList2(userStore.userInfo.tenantId);
    formItemsMap1.receive.options =
      res.result.filter((item) => item.userId != userStore.userInfo.userId) ||
      [];
  } else {
    let {
      result: { records },
    } = await apiUser.page({ pageNo: 1, pageSize: 100000 });

    saveData.value = formItemsMap1.receive.options =
      records.filter((item) => item.id != userStore.userInfo.userId) || [];
  }
};

const confirmLoading = ref(false);
const ok = async (status) => {
  try {
    await formRef1.value.formRef.validateFields();
    let noticeUserInfo = [];
    if (userStore.isTenantAdmin) {
      //租户管理登录
      if (formData1.msgType == 3) {
        //公告
        formItemsMap1.receive.options.forEach((item) => {
          noticeUserInfo.push({
            tenantId: item.tenantId,
            tenantName: "",
            userId: item.userId,
            userName: item.username,
          });
        });
      } else {
        //站内信
        formData1.noticeIds.forEach((id) => {
          let findOne = null;
          formItemsMap1.receive.options.forEach((item) => {
            if (item.userId == id) {
              findOne = item;
            }
          });
          if (findOne) {
            noticeUserInfo.push({
              tenantId: findOne.tenantId,
              tenantName: "",
              userId: findOne.userId,
              userName: findOne.username,
            });
          }
        });
      }
    } else {
      //平台管理员登录
      if (formData1.msgType == 3) {
        //公告
        formData1.noticeIds.forEach((id) => {
          let findOne = null;
          saveData.value.forEach((item) => {
            if (item.id == id) {
              findOne = item;
            }
          });
          if (findOne) {
            saveData.value.forEach((item) => {
              if (findOne.tenantId == item.tenantId) {
                noticeUserInfo.push({
                  tenantId: item.tenantId,
                  tenantName: "",
                  userId: item.id,
                  userName: item.username,
                });
              }
            });
          }
        });
      } else {
        //站内信
        formData1.noticeIds.forEach((id) => {
          let findOne = null;
          formItemsMap1.receive.options.forEach((item) => {
            if (item.id == id) {
              findOne = item;
            }
          });
          if (findOne) {
            noticeUserInfo.push({
              tenantId: findOne.tenantId,
              tenantName: "",
              userId: findOne.id,
              userName: findOne.username,
            });
          }
        });
      }
    }

    const params = {
      msgTitle: formData1.msgTitle,
      msgContent: formData1.msgContent,
      msgType: formData1.msgType,
      msgStatus: status,
      mailFlag: formData1.noticeType.includes(1) ? 1 : 0,
      emailFlag: formData1.noticeType.includes(2) ? 1 : 0,
      noticeUserInfo,
    };
    if (props.info) {
      params.id = props.info.id;
    }
    await apiMessage.save(params);
    if (status == "02") {
      message.success(`发布成功！`);
    } else {
      message.success(`${params.id ? "编辑成功" : "保存成功"}！`);
    }

    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.actions {
  text-align: right;
  button {
    margin-left: 10px;
  }
}
.project-edit-content {
  gap: 20px;
  /deep/.ant-row {
    justify-content: space-between;
  }
}
.project-edit-form {
  flex: 1;
}
/deep/.form2 {
  .ant-form-item-label {
    width: 89px;
  }
  .ant-form-item-control-input-content {
    section {
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  input {
    width: 320px;
  }
}
.bt-add {
  width: 100%;
  color: #0256ff;
  background-color: #ecf4ff;
  margin-top: 16px;
  font-size: 12px;
}
</style>
